/* @import url("https://fonts.googleapis.com/css2?family=El+Messiri:wght@400;500;600;700&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Lemonada:wght@300;400;500;600;700&display=swap"); */
body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 22px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.font1 {
  font-family: Arial, Helvetica, sans-serif;
}

.font2 {
  font-family: Arial, Helvetica, sans-serif;
}

.fs-7 {
  font-size: 15px !important;
}

.mt-n10 {
  top: -10rem;
  left: 0;
  right: 0;
  position: absolute;
}

.mb-150 {
  margin: 150px auto;
}

.nav-h {
  height: 90px;
}
@media screen and (max-width: 1200px) {
  .nav-h {
    height: 75px;
  }
  .logoText {
    white-space: pre-wrap;
    font-size: 15px;
    width: 50% !important;
  }
}

@media screen and (max-width: 991px) {
  .mb-150 {
    margin: 40px auto;
  }
  .mt-n10 {
    top: 0rem;
    position: initial;
  }
  .logoText {
    white-space: pre-wrap;
    font-size: 12px;
  }
}

@media screen and (max-width: 667px) {
  .logoText {
    white-space: pre-wrap;
    font-size: 12px;
    width: 75% !important;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
}

.ellip {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; /* start showing ellipsis when 3rd line is reached */
  white-space: pre-wrap; /* let the text wrap preserving spaces */
}
